<!-- 用户配置页面 -->
<template>
  <div class="memberConfig">
    <Tabs v-model="tabVal" :animated="false">
      <TabPane label="新用户配置" name="1">
        <div class="config-content">
          <Form :model="formData1" :label-width="140">
            <FormItem label="用户默认头像上传：">
              <Upload
                action="/api/upload/resources"
                :data="dataObj"
                :headers="headersData"
                :before-upload="handleUpload"
                :on-success="handleSuccess"
              >
                <div class="fc-files">
                  <img :src="formData1.defaultAvatar" />
                </div>
              </Upload>
              <p style="color:#c0c4cc">用户默认头像，后台添加用户以及用户登录的默认头像显示，尺寸(80*80)</p>
            </FormItem>
            <FormItem label="用户默认昵称：">
              <Input v-model="formData1.defaultNickname" style="width:400px"></Input>
            </FormItem>
            <FormItem label="赠送圈豆：">
              <Input v-model="formData1.defaultCoin" style="width:400px"></Input>
            </FormItem>
            <FormItem label="赠送优惠券：">
              <Select v-model="formData1.defaultCoupon" style="width:400px" clearable>
                <Option
                  v-for="item in dataList"
                  :value="item.id"
                  :key="item.id"
                >{{ item.couponName }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save(1)">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
      <TabPane label="圈豆配置" name="2">
        <div class="config-content">
          <Form :model="formData2" :label-width="120">
            <FormItem label="下单赠送圈豆：">
              <Input v-model="formData2.defaultOrderCoupon" style="width:400px"></Input>
              <p style="color:#c0c4cc">下单支付金额按比例赠送积分 (实际支付1元赠送多少积分)</p>
            </FormItem>
            <FormItem label="分享赠送圈豆：">
              <Input v-model="formData2.defaultShareCoupon" style="width:400px"></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save(2)">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { getUserConfig, editUserConfig, getCouponConfig, editCouponConfig } from '@/api/memberCenter.js'
import { getCouponList } from '@/api/couponManagement.js'

import { getSessionStore } from '@/libs/storage';
export default {
  data() {
    return {
      tabVal: '1',
      dataList: [
        // {
        //   value: '1',
        //   label: '1.8折信任优惠劵'
        // },
      ],
      formData1: {
        "defaultNickname": "",
        "defaultAvatar": "",
        "defaultCoin": "",
        "defaultCoupon": ""
      },
      formData2: {
        defaultOrderCoupon: '',
        defaultShareCoupon: '',
      },
      dataObj: {
        userId: this.$store.getters.userInfo.id
      },
      headersData: {
        'X-Token': getSessionStore('accessToken')
      }
    };
  },
  created() {
    this.findPageDataList()
    this.getUserConfig()
    this.getCouponConfig()
  },
  methods: {
    findPageDataList() {
      getCouponList({ size: 50, pageIndex: 1 }).then(res => {
        if (res.success) {
          this.dataList = res.result.list
        }
      })
    },
    getUserConfig() {
      getUserConfig().then(res => {
        if (res.success) {
          this.formData1 = res.result
        }
      })
    },
    getCouponConfig() {
      getCouponConfig().then(res => {
        if (res.success) {
          this.formData2.defaultOrderCoupon = res.result.defaultOrderCoupon
          this.formData2.defaultShareCoupon = res.result.defaultShareCoupon
        }
      })
    },
    save(type) {
      if (type == 1) {
        editUserConfig(this.formData1).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      } else {
        editCouponConfig(this.formData2).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      }

    },
    handleUpload(file) {
      console.log(file)
    },
    handleSuccess(res) {
      this.formData1.defaultAvatar = res.result;
    }
  }
}

</script>
<style lang='scss'>
.memberConfig {
  height: calc(100vh - 150px);
  .config-content {
    height: calc(100vh - 200px);
    padding-top: 10px;
    overflow: auto;
    .fc-files {
      display: inline-block;
      width: 58px;
      height: 58px;
      text-align: center;
      line-height: 58px;
      border: 1px solid #c0ccda;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
      margin-right: 4px;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
      }
    }
    .ivu-upload-list {
      display: none;
    }
  }
}
</style>