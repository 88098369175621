import { postRequest } from '@/libs/axios';


// 获取列表
export const getCouponList = (params) => {
    return postRequest('/api/user/coupon/list', params)
}
//添加编辑
export const addCoupon = (params) => {
    return postRequest('/api/user/coupon/add', params)
}
//删除
export const delCoupon = (params) => {
    return postRequest('/api/user/coupon/deleted', params)
}
